<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>團購中心</b-breadcrumb-item>
            <b-breadcrumb-item active>團購商品管理</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <b-overlay :show="showLoading" rounded="sm">
        <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
          <h4 class="col-12 col-xl-4 mb-1 mb-xl-0 font-weight-bold">
            團購商品管理
          </h4>
          <div
            class="
              col-12 col-xl-6
              d-flex
              flex-column flex-xl-row
              align-items-start align-items-xl-center
            "
          >
            <b-button
              v-if="checkPermission([consts.CORE_GROUPBUYING_PRODUCT_CREATE])"
              class="flex-shrink-0 mb-2 mb-xl-0 mr-3 py-2"
              variant="primary"
              :to="{
                name: 'CoreGroupbuyingsProductCreate',
                query: { merchant_id: this.query.merchant_id },
              }"
              ><i class="fa fa-plus" aria-hidden="true"></i>新增商品
            </b-button>
            <b-form-select
              v-model="query.merchant_id"
              :options="merchants"
              :disabled="merchants.length === 2"
              @change="handlePageReset"
            ></b-form-select>
            <b-input-group class="ml-0 ml-xl-4">
              <b-form-input
                placeholder="搜尋商品"
                v-model="query.keyword"
                v-on:keyup.enter="handlePageReset"
              ></b-form-input>
              <b-input-group-append>
                <b-button @click="handlePageReset"
                  ><i class="fa fa-search"></i
                ></b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
        <b-table responsive striped hover :items="products" :fields="fields">
          <template #cell(name)="data">
            <router-link
              :to="{
                name: 'CoreGroupbuyingsProductDetail',
                params: {
                  product_id: data.item.product_id,
                },
              }"
            >
              <div class="mb-1">{{ data.item.product.title }}</div>
              <div>{{ data.item.product.sale_page_code }}</div>
            </router-link>
          </template>
          <template #cell(sku)="data">
            <div class="mb-1">{{ data.item.sku_name }}</div>
          </template>
          <template #cell(picture)="data">
            <b-img-lazy
              v-if="
                data.item.product.images[0] &&
                data.item.product.images[0].pic_url
              "
              class="thumb-image"
              :src="
                data.item.product.images[0] &&
                data.item.product.images[0].pic_url
              "
              alt="商品圖片"
              width="50"
              height="50"
            />
          </template>
          <template #cell(action)="data">
            <b-button
              class="ml-2"
              variant="inverse-success"
              v-if="checkPermission([consts.CORE_GROUPBUYING_PRODUCT_MODIFY])"
              :to="{
                name: 'ProductUpdateImage',
                params: { product_id: data.item.product_id },
                query: { from: 'CoreGroupbuyingsProductList' },
              }"
            >
              <span class="mdi mdi-image-multiple"></span> 設定圖片
            </b-button>
            <b-button
              class="ml-2"
              variant="inverse-primary"
              v-if="checkPermission([consts.CORE_GROUPBUYING_PRODUCT_LIST])"
              :to="{
                name: 'CoreGroupbuyingsProductDetail',
                params: { product_id: data.item.product_id },
              }"
            >
              <span class="mdi mdi-eye mr-1"></span>查看
            </b-button>
            <b-button
              class="ml-2"
              variant="inverse-warning"
              v-if="checkPermission([consts.CORE_GROUPBUYING_PRODUCT_MODIFY])"
              :to="{
                name: 'CoreGroupbuyingsProductEdit',
                params: { product_id: data.item.product.id },
              }"
            >
              <span class="mdi mdi-lead-pencil mr-1"></span>編輯
            </b-button>
            <b-button
              class="ml-2"
              variant="inverse-danger"
              @click="handleDelete(data.item.product.id)"
              v-if="checkPermission([consts.CORE_GROUPBUYING_PRODUCT_MODIFY])"
            >
              <span class="mdi mdi-delete mr-1"></span>刪除
            </b-button>
          </template>
        </b-table>
        <CustomPagination
          :currentPage="query.page"
          :totalRows="totalRows"
          :perPage="query.per_page"
          @page-change="handlePageChange"
          @per-page-change="handlePerPageChange"
        />
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import groupbuyApi from "@/apis/groupbuy";
import merchantApi from "../../../apis/merchant";
import productApi from "../../../apis/product";
import moment from "moment";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";
import { paginationMixin } from "@/mixins/pagination";

export default {
  components: { CustomPagination },
  mixins: [paginationMixin],
  data() {
    return {
      consts,
      showLoading: false,
      merchants: [{ value: null, text: "請選擇" }],
      totalRows: 0,
      fields: [
        {
          key: "name",
          label: "商品名稱/編號",
        },
        {
          key: "sku",
          label: "商品規格",
        },
        {
          key: "picture",
          label: "商品圖片",
        },
        {
          key: "action",
          label: "管理",
        },
      ],
      products: [],
      permissionChecker: new PermissionChecker(),
      query: {
        page: 1,
        per_page: 10,
        keyword: "",
        merchant_id: null,
      },
      initialized: false,
    };
  },
  created() {
    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);
    this.init();
  },
  computed: {
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    $route(to) {
      if (!this.initialized) return;
      this.query = { ...to.query };
      this.fetchProducts();
    },
  },
  methods: {
    async init() {
      await this.fetchMerchants();
      this.initialized = true;
    },
    async fetchProducts() {
      if (!this.query.merchant_id) return;

      this.showLoading = true;

      try {
        const params = { ...this.query };

        const { data } = await groupbuyApi.getSkus(params);

        this.products = data.data;
        this.totalRows = data.meta.total;
        this.perPage = data.meta.per_page;
      } catch (error) {
        console.error(error);
        alert("取得商品資料錯誤");
      }
      this.showLoading = false;
    },
    async fetchMerchants() {
      const { data } = await merchantApi.list();

      this.merchants = [
        { value: null, text: "請選擇" },
        ...data.data.map((merchant) => {
          return {
            value: merchant.id,
            text: `${merchant.name} (${merchant.type_name})`,
          };
        }),
      ];

      let defaultMerchant = this.findDefaultMerchant(data.data);

      if (defaultMerchant) {
        this.query.merchant_id = defaultMerchant.id;
        await this.fetchProducts();
      }
    },
    findDefaultMerchant(merchants) {
      let defaultMerchant;

      if (this.$route.query.merchant_id) {
        defaultMerchant = merchants.find((merchant) => {
          return merchant.id === this.$route.query.merchant_id;
        });

        if (!defaultMerchant) {
          this.$swal.fire({
            type: "warning",
            title: "查無此通路",
            confirmButtonText: "確定",
            confirmButtonColor: "#d33",
            reverseButtons: true,
          });
          return;
        }
      } else if (merchants.length === 1) {
        defaultMerchant = merchants[0];
      } else {
        defaultMerchant = merchants
          .filter((merchant) => merchant.type === "LINE")
          .shift();
      }

      return defaultMerchant;
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD HH:mm");
      }
    },
    async handleDelete(id) {
      this.$swal
        .fire({
          type: "warning",
          title: "你確定要刪除嗎？",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              const response = await productApi.deleteProduct(id);
              if (response.status === 200) {
                await this.$swal.fire({
                  title: "成功",
                  type: "success",
                  text: "刪除成功",
                });
                await this.fetchProducts();
              }
            } catch (error) {
              this.$swal("失敗", error.response.data.message, "error");
            }
          }
        });
    },
    checkPermission(permissions) {
      return this.permissionChecker.check(permissions);
    },
  },
};
</script>

<style>
.modal-backdrop {
  opacity: 0.5;
}
</style>
